import React from "react";
import QuestionBubble from "../QuestionBubble"
import SmartForm from "../SmartForm"
import { withTranslation } from "react-i18next"
import Heading from "../Heading"
import rehypeReact from 'rehype-react'
import MarkdownModalImage from '../MarkdownModalImage'
import SocialShare from "../SocialShare"
import './conference.scss'
import Meta from "../Meta";

class Conference extends React.Component {

    state = {
        reservationDate: ""
    }

    componentDidMount() {

        const { locale } = this.props
        const $ = require('jquery')
        require("air-datepicker")
        require("air-datepicker/dist/js/i18n/datepicker." + locale)

        const dpInit = {
            language: locale,
            minDate: new Date(),
            position: "top left",
            timepicker: true,
            minuteStep: 15,
            onSelect: (formattedDate, newDate) => {
                this.setState({reservationDate: formattedDate})
            }
        }

        if (dpInit.language === 'hu') {
            dpInit.dateFormat = "yyyy.mm.dd.";
            dpInit.timeFormat = "hh:mm"
        }

        $(".reservation-date").datepicker(dpInit);
    }

    
    render() {

        const { t, data, content } = this.props
        const dateFormat = t('dateFormat')

        const renderAst = new rehypeReact({
            createElement: React.createElement,
            components: { 'markdown-image': MarkdownModalImage },
        }).Compiler
    
        return(
            <div id="conference" class="blog-area blog-no-sidebar pt-100 pb-100">
            <Meta
                keywords={data.keywords}
                description={data.description}
            />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    <Heading title={data.title} />
                                    { renderAst(content) }
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev mt-60">
                                <SocialShare title={data.title} hashtags="giftcard" />
                            </div>
                        </div>
                    </div>
                    <div class="row question-area pb-50">
                        <div class="col-lg-8 col-md-12">
                            <h3>{t('would_you_like_to_reserve_a_conference_room')}</h3>                    
                            <SmartForm 
                                successTitle={t('your_reservation_request_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="conference-form" 
                                formName="conference-reservation" 
                                mailSubject="Konferencia terem foglalás érkezett"
                                stateProps={["name", "email", "phone", "reservation-date", "conference-room", "message"]}
                                stateValues={{["reservation-date"]: () => this.state.reservationDate}}
                                defaultMessageFields={["name", "phone", "reservation-date", "email"]}>
                                <div class="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="name" placeholder={t('my_name')} type="text" required />
                                        </div>  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="email" type="email" placeholder={t('my_email')} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="phone" type="phone" placeholder={t('my_phone')} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input select date form-group">
                                            <input className="reservation-date" autocomplete="off" name="reservation-date" type="text" placeholder={t('the_reservation_date')} required />
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="select form-group">
                                            <select name="conference-room" required >
                                                <option value="">{t('choose_room')}</option>
                                                <option value="Hárs 1. terem">{t('hars_1_room')}</option>
                                                <option value="Hárs 2. terem">{t('hars_2_room')}</option>
                                                <option value="Lavender Luxury Lounge">{t('lavender_lounge')}</option>
                                                <option value="Robinia terem">{t('robinia_room')}</option>
                                                <option value="Salix terem">{t('salix_room')}</option>
                                            </select>
                                        </div>  
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input form-group">
                                            <textarea className="form-control" name="message" placeholder={t('my_message')} />
                                        </div>  
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="sent-btn form-group col-lg-auto" type="submit">
                                            {t('send_reservation')}
                                        </button>
                                    </div>
                                </div>
                            </SmartForm>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <QuestionBubble 
                                title={data.questionTitle} 
                                email={data.questionEmail} 
                                phone={data.questionPhone} 
                                description={data.questionDescription}>
                                <img className="homoky_logo" src="/images/logo/hh.svg" alt="Homoky hotels logo" />
                            </QuestionBubble>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Conference)