import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Conference from "../components/Conference"

const ConferencePage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title} keywords={fields.keywords} description={fields.description}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} textShadow={true} />
          <Conference data={fields} content={data.page.htmlAst} locale={localeData.locale} />
      </Layout>
    );
};
export default withI18next()(ConferencePage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      htmlAst
      frontmatter {
        slug
        title
        date
        contentDescription
        keywords
        description
        questionTitle
        questionEmail
        questionPhone
        questionDescription
      }
    }
    bgImage: file(relativePath: { eq: "bg/bg_conference.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`